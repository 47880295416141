export default {
  sectionList: [
    {
      subtitle: '',
      content: [
        {
          partType: 'string',
          value: '我们很高兴地宣布，飞狼的母公司Fenrir Inc.已正式成为日本微软株式会社（总部：东京都港区，以下简称“日本微软”）【生成式AI业务化支持计划】的合作伙伴。',
        },
      ],
    },
    {
      subtitle: '什么是【生成式AI业务化支持计划】？',
      content: [
        {
          partType: 'string',
          value:
          // eslint-disable-next-line max-len
          '【生成式AI业务化支持计划】是日本微软面向合作伙伴推出的支持项目，旨在与合作伙伴共同推动生成式AI的安全扩展与广泛应用。通过这一计划，致力于为更多的客户提供一个可以安心使用生成AI的环境。\n\nFenrir Inc.基于Azure OpenAI Service，能够帮助更多的客户利用生成式AI，为客户实现业务价值的最大化。',
        },
      ],
    },
    {
      subtitle: 'Fenrir Inc.的生成式AI的支持优势',
      content: [
        {
          partType: 'string',
          value: 'Fenrir Inc.提供从生成式AI的导入到运营的一站式支持，为客户提供最优解决方案。',
        },
        {
          partType: 'string',
          value:
          // eslint-disable-next-line max-len
          "<ul style='list-style-position: inside; list-style-type: decimal; padding-left: 1em;'><li><strong>生成式AI的导入评估“3DAYS”</strong><div style='padding-left: 1em;'>“3DAYS”是Fenrir Inc.推出的一项有效导入生成式AI技术的战略评估服务。在为期三天的工作坊中，专业团队将深入了解客户的业务需求与挑战，并快速制定符合其业务模式的生成式AI战略。</div></li><li><strong>生成式AI的系统开发与运营支持</strong><div style='padding-left: 1em;'>Fenrir Inc.的生成式AI专业团队利用Azure OpenAI Service，为客户设计最适合业务需求的系统架构。并活用在云服务及应用程序开发中积累的UX/UI专业知识，为客户打造运营高效且优质的生成式AI系统。</div></li></ul>",
        },
        {
          partType: 'string',
          value:
          // eslint-disable-next-line max-len
          "了解更多关于Fenrir Inc.云服务支持“GIMLE”的信息，请访问以下页面：\n<a target='_blank' href='https://www.fenrir-inc.com/jp/business/gimle/'>https://www.fenrir-inc.com/jp/business/gimle/</a>",
        },
        {
          partType: 'string',
          value:
          // eslint-disable-next-line max-len
          "参考资料：\n生成式AI业务化支持计划\n<a target='_blank' href='https://www.microsoft.com/ja-jp/biz/find-new-value-on-azure/ai-biz'>https://www.microsoft.com/ja-jp/biz/find-new-value-on-azure/ai-biz</a>\n※Microsoft 和 Azure 是美国 Microsoft Corporation 在美国及其他国家的注册商标或商标。\n※文中提及的公司名称和产品名称均为各自公司的商标或注册商标。",
        },
      ],
    },
  ],
};
