export default {
  sectionList: [
    {
      subtitle: '',
      content: [
        {
          partType: 'string',
          // eslint-disable-next-line max-len
          value: '私たち飛狼の親会社であるFenrir Inc. は、日本マイクロソフト株式会社（本社:東京都港区、以下、日本マイクロソフト）の「生成 AI 事業化支援プログラム」パートナーに参加したことをお知らせします。',
        },
      ],
    },
    {
      subtitle: '生成AI事業化支援プログラムとは',
      content: [
        {
          partType: 'string',
          value:
          // eslint-disable-next-line max-len
          '生成AI事業化支援プログラムは、日本マイクロソフトのパートナー向けプログラムです。日本マイクロソフトと参加パートナーが連携し、生成AIの利用を安全に拡大・活性化することを目的としています。このプログラムにより、より多くのお客さまが安心して生成AIを活用できる環境の提供を目指します。\n\nFenrir Inc. は、Azure OpenAI Serviceを活用して、より多くのお客さまが生成AIを効果的に活用できるよう支援し、お客さまのビジネス価値を最大化することを目指しています。',
        },
      ],
    },
    {
      subtitle: '飛狼の親会社フェンリルの生成AI活用支援の特長',
      content: [
        {
          partType: 'string',
          value: '生成AIの導入から運用まで一貫してサポートし、お客さまに最適なソリューションを提供します。',
        },
        {
          partType: 'string',
          value:
          // eslint-disable-next-line max-len
          "<ul style='list-style-position: inside; list-style-type: decimal; padding-left: 1em;'><li><strong>生成AI導入アセスメント「3DAYS」</strong><div style='padding-left: 1em;'>3DAYSは、生成AI技術を効果的に導入するための戦略アセスメントプログラムです。3日間集中のワークショップでビジネスニーズと課題をヒアリングし、ビジネスモデルに合った生成AI戦略を迅速に策定します。</div></li><li><strong>生成AIシステム開発・運用支援</strong><div style='padding-left: 1em;'>Fenrir Inc.の生成AI専門チームが、Azure OpenAI Serviceを活用しお客さまに最適なアーキテクチャを設計します。クラウドの活用経験とアプリ開発で培ったUX/UIの知見を生かし、効率的で高品質な生成AIシステムの構築と運用を支援します。</div></li></ul>",
        },
        {
          partType: 'string',
          value:
          // eslint-disable-next-line max-len
          "Fenrir Inc.のクラウド活用支援サービス「GIMLE」の詳細、お問い合わせは下記ページをご覧ください。\n<a target='_blank' href='https://www.fenrir-inc.com/jp/business/gimle/'>https://www.fenrir-inc.com/jp/business/gimle/</a>",
        },
        {
          partType: 'string',
          value:
          // eslint-disable-next-line max-len
          "＜参照＞\n生成AI事業化支援プログラム\n<a target='_blank' href='https://www.microsoft.com/ja-jp/biz/find-new-value-on-azure/ai-biz'>https://www.microsoft.com/ja-jp/biz/find-new-value-on-azure/ai-biz</a>",
        },
      ],
    },
    {
      subtitle: '',
      content: [
        {
          partType: 'string',
          // eslint-disable-next-line max-len
          value: "生成AIの活用に関する不明点や、活用したいニーズがございましたら、弊社でご支援することが可能です。下記までお問い合わせください。\n\nお問い合わせ：\n飛狼数碼（上海）有限公司\nメール：<a href='info@fenrir-inc.com.cn'>info@fenrir-inc.com.cn</a>",
        },
      ],
    },
    {
      subtitle: '',
      content: [
        {
          partType: 'string',
          // eslint-disable-next-line max-len
          value: '※Microsoft、Azureは、米国Microsoft Corporationの米国およびその他の国における登録商標または商標です。\n※記載された会社名・商品名は、それぞれ各社の商標または登録商標です。',
        },
      ],
    },
  ],
};
